<template>
  <div class="row mb-3 mt-5 p-3 pt-5">
    <div class="col">
        <h2>{{ t('admon.entities.title') }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 mb-4"><button class="btn btn-primary form-control" @click="addEntityRow()">{{ t('admon.entities.add') }}</button></div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 mb-4" v-for="entity in newEntities" :key="entity.id">
      <div class="card border-primary">
        <div class="card-body">
          <input class="form-control" :class="entity.name ? 'input-title': ''" v-model="entity['name']" @change="editedEntity(entity, 'name')" :placeholder="t('admon.entities.name')">
          <div class="row mt-2">
            <div class="col"><label><input type="checkbox" v-model="entity['open_date_limits']" @change="editedEntity(entity, 'open_date_limits')"> {{ openDatesLimitLabel(entity.open_date_limits) }}</label></div>
          </div>
          <div class="row mt-2">
            <div class="col"><button class="btn btn-primary me-2 form-control" v-if="editedEntities[entity.id]" @click="createEntity(entity)"><font-awesome-icon :icon="['fas', 'save']"  /></button></div>
            <div class="col-4"><button class="btn btn-danger form-control" @click="deleteEntity(entity)"><font-awesome-icon :icon="['fas', 'trash']"  /></button></div>
          </div>
        </div>
        <div class="card-footer text-grey"><small>{{ formatDateTime(entity.date_m) }}<br>{{ entity.user_c_fullname }}</small></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 mb-4" v-for="entity in sortedEntities" :key="entity.id">
      <div class="card border-dark">
        <div class="card-body">
          <input class="form-control" :class="allEntities[entity.id]['name'] ? 'input-title': ''" v-model="allEntities[entity.id]['name']" @change="editedEntity(allEntities[entity.id], 'name')" :placeholder="t('admon.entities.name')">
          <div class="row mt-2">
            <div class="col"><label><input type="checkbox" v-model="allEntities[entity.id]['open_date_limits']" @change="editedEntity(allEntities[entity.id], 'open_date_limits')"> {{ openDatesLimitLabel(allEntities[entity.id].open_date_limits) }}</label></div>
          </div>
          <div class="row mt-2">
            <div class="col"><button class="btn btn-primary me-2 form-control" v-if="editedEntities[entity.id]" @click="updateEntity(allEntities[entity.id])"><font-awesome-icon :icon="['fas', 'save']"  /></button></div>
            <div class="col-4"><button class="btn btn-secondary form-control" @click="deleteEntity(entity)"><font-awesome-icon :icon="['fas', 'trash']"  /></button></div>
          </div>
        </div>
        <div class="card-footer text-grey"><small>{{ formatDateTime(entity.date_m) }}<br>{{ entity.user_c_fullname }}</small></div>
      </div>
    </div>
  </div>
  </template>
  
  
  <script>
  // @ is an alias to /src¡
  import { useI18n } from 'vue-i18n';
  import shared from '@/components/shared';
  import axios from 'axios';
  import sharedMixin from '@/mixins/sharedMixin';
  
  export default {
    name: 'AdmonEntities',
    mixins: [sharedMixin],
    created(){
      },
    components: {
      
    },
    data() {
      return {
        allEntities: {},
        editedEntities: {},
        originalEntities: {},
        newEntities: {},
      }
    },
    computed: {
      sortedEntities() {
      return Object.values(this.originalEntities).sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    methods: {
      addEntityRow(){
        let entityId = 'NEW_' + Date.now();
        this.newEntities[entityId] = { id: entityId, name: '', open_date_limits: false};
      }, 
      createEntity(entity){
        axios.post('/api/v1/clients/', entity)
        .then(response => {
          if('id' in response.data){
            this.showToast(this.t('admon.entities.created'));
            this.allEntities[response.data.id] = response.data;
            this.copyAllEntities();
            delete this.newEntities[entity.id];
            delete this.editedEntities[entity.id];
          }
          else this.handleGeneralError(response);
        })
        .catch(error => {
          this.handleGeneralError(JSON.stringify(error.response.data));
        });
      },
      copyAllEntities(){
        this.originalEntities = this.copyVar(this.allEntities);
      },
      deleteEntity(entity){
        if(confirm(this.t('admon.entities.confirm_delete') + ' ' + entity.name + '?')) 
        {
          if(entity.id.startsWith('NEW_')) delete this.newEntities[entity.id];
          else
          {
            axios.delete('/api/v1/clients/' + entity.id + '/')
            .then(response => {
              if(response.status == 204){
                this.showToast(this.t('admon.entities.deleted'));
                delete this.allEntities[entity.id];
                delete this.originalEntities[entity.id];
                delete this.editedEntities[entity.id];
              }
              else this.handleGeneralError(response);
            })
            .catch(error => {
              this.handleGeneralError(JSON.stringify(error.response.data));
            });
          }
        }
      },
      editedEntity(entity, field){
        if(
          entity.id in this.editedEntities && 
          (entity.id in this.originalEntities && this.allEntities[entity.id][field] == this.originalEntities[entity.id][field]) 
          || (entity.name == '')
          )
        {
          delete this.editedEntities[entity.id][field];
          if(entity.name=='') entity.open_date_limits = false;
          if(Object.keys(this.editedEntities[entity.id]).length == 0 || entity.name == '') delete this.editedEntities[entity.id];
        }
        else
        {
          if(!this.editedEntities[entity.id]) this.editedEntities[entity.id] = {};
          this.editedEntities[entity.id][field] = entity[field]; 
        }
      },
      openDatesLimitLabel(flag)
      {
        return flag ? this.t('admon.entities.open_date_limits') : this.t('admon.entities.open_date_limits_closed');
      },
      updateEntity(entity){
        axios.patch('/api/v1/clients/' + entity.id + '/', this.editedEntities[entity.id])
        .then(response => {
          if(response.status == 200){
            this.allEntities[entity.id] = response.data;
            this.copyAllEntities();
            delete this.editedEntities[entity.id];
            this.showToast(response.data.name + this.t('admon.entities.updated'));
          }
          else this.handleGeneralError(response);
        })
        .catch(error => {
          this.handleGeneralError(JSON.stringify(error.response.data));
        });
      },
    },
    setup() {
        const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
        })
        return { t }
    },
    async mounted() {
      await this.getAllEntities(this.copyAllEntities);
    },
  }
  </script>