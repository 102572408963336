import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import CalendarMain from '../views/calendar/CalendarMain.vue'
import CalendarWeek from '../views/calendar/CalendarWeek.vue'
import CalendarDay from '../views/calendar/CalendarDay.vue'
import AdmonMain from '../views/admon/AdmonMain.vue'
import AdmonEntities from '../views/admon/AdmonEntities.vue'
import AdmonAccounts from '../views/admon/AdmonAccounts.vue'

import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  /*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  */
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/calendar/:menutype/:year/:month',
    name: 'CalendarMonth',
    component: CalendarMain,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/calendar/week/:menutype/:year/:weeknum',
    name: 'CalendarWeek',
    component: CalendarWeek,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/calendar/:menutype',
    name: 'CalendarMain',
    component: CalendarMain,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/calendar/day/:menutype/:date/',
    name: 'CalendarDay',
    component: CalendarDay,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/admon',
    name: 'AdmonMain',
    component: AdmonMain,
    meta: {
      requireLogin: true,
      requireAdmin: true,
      isAdmonView: true
    }
  },
  {
    path: '/admon/entities',
    name: 'AdmonEntities',
    component: AdmonEntities,
    meta: {
      requireLogin: true,
      requireAdmin: true,
      isAdmonView: true
    }
  },
  {
    path: '/admon/clients',
    name: 'AdmonAccounts',
    component: AdmonAccounts,
    meta: {
      requireLogin: true,
      requireAdmin: true,
      isAdmonView: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated)
  {
    next('/')
  }
  else if(to.matched.some(record => record.meta.requireAdmin) && !store.state.user.role == 'admin')
  {
    next('/')
  }
  else 
  {
    next()
  }
})

const DEFAULT_TITLE = 'FMM COLLECTIVE';
router.afterEach((to, from) => {
        document.title = DEFAULT_TITLE + ( to.meta.title ?  ' - ' + to.meta.title : '' ) ;
});

export default router