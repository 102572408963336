<template>
  <div class="row mb-3 mt-5 align-items-center">
    <div class="col-auto">  
      <button class="btn btn-secondary" @click="previousMonth()"><font-awesome-icon :icon="['fas', 'arrow-left']"  /></button>
     </div>
    <div class="col text-center">
      <h2>{{ monthName }} {{ this.currMonth.getFullYear() }} </h2>
    </div>
    <div class="col-auto text-center" v-if="this.$store.state.user.role=='admin'">
      <select class="form-select" v-model="currView" @change="getTotalMenusByEntity()">
        <option value="daymenus">{{ t('daymenu.calendar_byday') }}</option>
        <option v-for="(entityChars, entityUid) in allEntities" :value="entityUid">
          {{ t('daymenu.calendar_bymonth') }} - {{ entityChars.name }}
        </option>
      </select>
      <span v-if="monthTotalTitle!=''"><br><b>{{ monthTotalTitle }}</b></span>
    </div>
    <div class="col-auto"> 
      <button class="btn btn-secondary" @click="nextMonth()"><font-awesome-icon :icon="['fas', 'arrow-right']"  /></button>
    </div>
  </div>
  <div class="row">
    <div class="col text-center calendar-header-day" v-for="d in 8">
    <span class="d-none d-md-block">{{ t('calendar.days.' + (d - 1)) }}</span>
    <span class="d-md-none">{{ t('calendar.days.' + (d - 1)).substring(0,1) }}</span>
    </div>
  </div>
  <div class="row" v-for="w in monthWeeks">
    <div class="col text-center calendar-day p-0" v-for="d in w">
      <template v-if="currView!='daymenus'">
        <template v-if="Number.isInteger(d)">
          <CalendarMonthWeek :week="d" :refDay="this.currMonth" :menutype="this.$props.menutype" />
        </template>
        <template v-else>
            <CalendarMonthDayByEntity :day="d" :refDay="this.currMonth" :dayTotals="getDayTotals(d)"  :menutype="this.$props.menutype"/>
        </template>
      </template>
      <template v-else>
        <template v-if="Number.isInteger(d)">
          <CalendarMonthWeek :week="d" :refDay="this.currMonth" :menutype="this.$props.menutype" />
        </template>
        <template v-else>
            <CalendarMonthDay :day="d" :refDay="this.currMonth" :dayMenu="getDayMenu(d)" :menutype="this.$props.menutype"/>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import shared from './shared'
import CalendarMonthDay from "./CalendarMonthDay.vue";
import CalendarMonthDayByEntity from "./CalendarMonthDayByEntity.vue";
import CalendarMonthWeek from "./CalendarMonthWeek.vue";
import { identifier } from "@babel/types";

export default defineComponent({
  name: "CalendarMonth",
  created(){
    this.ucFirst = shared.ucFirst,
    this.formatDateRaw = shared.formatDateRaw,
    this.formatDate = shared.formatDate,
    this.formatDateTime = shared.formatDateTime,
    this.getAllEntities = shared.getAllEntities,
    this.handleGeneralError = shared.handleGeneralError,
    this.inMonth = shared.inMonth,
    this.logout = shared.logout,
    this.menuChars = shared.menuChars,
    this.sumOrders = shared.sumOrders,
    this.showToast = shared.showToast
  },
  props: {
    month: String, 
    year: String, 
    menutype: String,
  },
  data() {
    return {
      currMonth: new Date(),
      allEntities: {},
      allDates: [],
      currView: 'daymenus',
      dayMenus: {},
      dayLimits: {},
      dayTotals: {},
      monthTotals: {},
    }
  },
  computed: {
    dateEnd(){
      let end_date = this.allDates[this.allDates.length - 1];
      end_date = end_date.getFullYear() + '-' + ((end_date.getMonth()+1) < 10 ? '0' : '') + (end_date.getMonth()+1) + '-' + (end_date.getDate() < 10 ? '0' : '') + end_date.getDate() ;
      return end_date;
    },
    dateStart(){
      let start_date = this.allDates[0];
      start_date = start_date.getFullYear() + '-' + ((start_date.getMonth()+1) < 10 ? '0' : '') + (start_date.getMonth()+1) + '-' + (start_date.getDate() < 10 ? '0' : '') + start_date.getDate() ;
      return start_date;
    },
    daysToFirst(){
      return this.currMonth.getDay() || 7 
    },
    monthDays(){
      return this.monthLastDay.getDate()
    },
    monthLastDay() {
      return new Date(this.currMonth.getFullYear(), this.currMonth.getMonth() + 1, 0)
    },
    monthName(){
      return this.t('calendar.months.' + this.currMonth.getMonth() )
    },
    monthTotalTitle(){
      return this.monthTotals.total ? `${this.monthTotals.total} ${this.t('daymenu.orderedmenus')}` : '';
    },
    monthWeeks(){
      let w = 0, retour = [];
      this.allDates = [];
      //console.log('totalDays - ', this.totalDays);
      for(let d = 1; d <= this.totalDays; d++)
      {
        let currDate = new Date(this.currMonth.getFullYear(), this.currMonth.getMonth(), 1);
        let toAddRemove = 0 - this.whiteDaysFirst + d 
        currDate.setDate(toAddRemove)

        if(!retour[w])
        {
          retour[w] = [];
          retour[w].push(this.weekNumber(currDate))
        }
        retour[w].push(currDate)
        this.allDates.push(currDate)
        if(d % 7 == 0)
         {
        
          w = w + 1;
         }
      }
      return retour
    },
    totalDays() {
      return this.whiteDaysFirst + this.monthDays + this.whiteDaysEnd;
    },
    whiteDaysFirst() {
      return this.daysToFirst - 1
    },
    whiteDaysEnd() {
      return this.monthLastDay.getDay() > 0 ?  7 - this.monthLastDay.getDay() : 0
    }
  },
  methods: {
    getDayMenu(d){
      if(Number.isInteger(d)) return null;
      return this.dayMenus[this.formatDateRaw(d)];
    },
    getDayTotals(d){
      if(Number.isInteger(d)) return null;
      return this.dayTotals[this.formatDateRaw(d)];
    },
    getMenus(){
      this.dayMenus = [];
      axios
        .get(`/api/v1/daymenus/${this.$props.menutype}/${this.dateStart}/${this.dateEnd}/`)
        .then(response => {
          //console.log(response.data);
          for(let currDate in response.data)
          {
            this.dayMenus[currDate] = response.data[currDate]
          }
          //console.log(this.dayMenus);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.handleGeneralError(error);
        })
    },
    getTotalMenusByEntity(){
      this.dayTotals = {};
      this.monthTotals = {'m': 0, 's': 0, 'total': 0};
      if(this.currView!='daymenus')
      {
        axios
        .get(`/api/v1/daymenus/${this.$props.menutype}/${this.dateStart}/${this.dateEnd}/${this.currView}/`)
        .then(response => {
          //console.log(response.data);
          for(let currDate in response.data)
          {
            this.dayTotals[currDate] = response.data[currDate];
            let currDateSplit = currDate.split('-');
            let currDateAsDate = new Date(currDateSplit[0], parseInt(currDateSplit[1]) - 1, parseInt(currDateSplit[2]));
            if(this.inMonth(this.currMonth, currDateAsDate))
            {
              this.monthTotals['m'] += response.data[currDate]['menustotal_m'] ? parseInt(response.data[currDate]['menustotal_m']) : 0;
              this.monthTotals['s'] += response.data[currDate]['menustotal_s'] ? parseInt(response.data[currDate]['menustotal_s']) : 0;
              this.monthTotals['total'] = this.monthTotals['m'] + this.monthTotals['s'];
            }
          }
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.handleGeneralError(error);
        })
      }
    },
    async previousMonth(){
      let newMonth = new Date(this.currMonth.getFullYear(), this.currMonth.getMonth(), 1)
      newMonth.setDate(newMonth.getDate() - 1);
      await this.setMonth(newMonth.getMonth() + 1, newMonth.getFullYear() )
      await this.getMenus()
      if(this.currView!='daymenus') await this.getTotalMenusByEntity();
    },
    async nextMonth(){
      //console.log(this.currMonth.getFullYear(), this.currMonth.getMonth() );
      //console.log(this.currMonth.getMonth() + 1 );
      let newMonth = new Date(this.currMonth.getFullYear(), this.currMonth.getMonth() + 1, 1)
      await this.setMonth(newMonth.getMonth() + 1, newMonth.getFullYear() )
      await this.getMenus()
      if(this.currView!='daymenus') await this.getTotalMenusByEntity();
      //console.log(newMonth.getMonth(), newMonth.getFullYear() );
    },
    setMonth(m, y){
      let newMonth = (m ? parseInt(m) - 1 : this.currMonth.getMonth())
      let newYear = (y ? parseInt(y) : this.currMonth.getFullYear())
      let currDate = new Date(newYear, newMonth, 1)
      this.currMonth = currDate;
      //this.currView = 'daymenus';
      //this.monthTotals = {'m': 0, 's': 0, 'total': 0};
      //console.log('setMonth ', this.currMonth)
    },
    weekNumber(currentDate) {
      let startDateUTC = new Date(Date.UTC(this.currMonth.getFullYear(), 0, 1));
      let currentDateUTC = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
      let days = Math.floor((currentDateUTC - startDateUTC) / (24 * 60 * 60 * 1000))
      let weekNumberResult = Math.ceil((currentDate.getDay()+days+1)/7) ;

      let firstDayOfYear = startDateUTC.getDay();
      if (firstDayOfYear != 1) weekNumberResult += 1; 
      return weekNumberResult
    },

  },
  async mounted() {
    await this.setMonth(this.month, this.year);
    await this.getMenus();
    if(this.$store.state.user.role=='admin') await this.getAllEntities();
  },
  components: {
    CalendarMonthDay,
    CalendarMonthDayByEntity,
    CalendarMonthWeek,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    return { t };
  },
});
</script>
