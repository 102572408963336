<template>

</template>


<script>
// @ is an alias to /src¡
import { useI18n } from 'vue-i18n';

export default {
  name: 'AdmonMain',
  components: {
    
  },
  setup() {
      const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
      })
      return { t }
  },
}
</script>