import axios from 'axios';
import moment from 'moment';
import { toast } from 'bulma-toast';

moment.locale('FR');

function ucFirst(string) {
    if(string) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    else return;
}

export default {
    copyVar: function(obj){
      return JSON.parse(JSON.stringify(obj));
    },
    dictLength(dict){
      return Object.keys(dict).length;
    },
    formatDateRaw: function(d){
        return moment(String(d)).format('YYYY-MM-DD')
    },
    formatDate: function(d, short=false, custom)
    {
        return ucFirst(moment(d).format((custom ? custom : (short ? 'll' : 'dddd LL'))));
    },
    formatDateTime: function(d, short=false)
    {
        return ucFirst(moment(d).format((short ? 'lll' : 'LLLL')));
    },
    getAllEntities(callbackSuccess, callbackError){
      axios
          .get(`/api/v1/clients/`)
          .then( response => {
            for(let e in response.data)
            {
              this.allEntities[response.data[e].id] = response.data[e]
            }
            if(callbackSuccess) callbackSuccess();
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            this.handleGeneralError(error);
            if(callbackError) callbackError();
          })
    },
    handleGeneralError: function(error)
    {
      if(error.response && error.response.status==401) { 
        this.logout();
      }
      else
      {
        this.showToast(error, 'error');
      }
    },
    inMonth: function (refDay, currDay){
      return currDay instanceof Date ? currDay.getMonth() == refDay.getMonth() : false ;
    },
    logout()
    {
      axios
            .post('/api/v1/token/logout/')
            .then(response => {
                axios.defaults.headers.common['Authorization'] = ""
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                localStorage.removeItem('userid')
                localStorage.removeItem('first_name')
                localStorage.removeItem('last_name')
                localStorage.removeItem('role')
                localStorage.removeItem('entity_name')
                localStorage.removeItem('entity')
                this.$store.commit('removeToken')
                this.$router.push('/')
            })
            .catch(error => {
                if(error.response){ 
                  if(error.response.data['detail']=="Invalid token.")  
                  {
                    axios.defaults.headers.common['Authorization'] = ""
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    localStorage.removeItem('userid')
                    localStorage.removeItem('first_name')
                    localStorage.removeItem('last_name')
                    localStorage.removeItem('role')
                    localStorage.removeItem('entity_name')
                    localStorage.removeItem('entity')
                    this.$store.commit('removeToken')
                    this.$router.push('/')
                  }
                  else {
                    console.log(JSON.stringify(error.response.data)); 
                  }
                }
                else if(error.message) { console.log(JSON.stringify(error.message)); }
                else { console.log(JSON.stringify(error)); }
            })
    },
    menuChars: function(menutype){
      let retour = {};
      if(menutype=='horizons')
      {
        retour['menu'] = {title: 'menus', elements: ['title1', 'title2'], max:2};
      }
      else if(menutype == 'collective')
      {
        retour['starter'] = {title: 'starters', elements: ['starter1', 'starter2'], max:2};
        retour['main'] = {title: 'mains', elements: ['main1', 'main2', 'main3', 'main4'], max:2};
        retour['side'] = {title: 'sides', elements: ['side1', 'side2', 'side3', 'side4'], max:2};
        retour['dairy'] = {title: 'dairy', elements: ['dairy1', 'dairy2'], max:2};
        retour['dessert'] = {title: 'desserts', elements: ['dessert1', 'dessert2'], max:2};
        retour['bread'] = {title: 'bread', elements: ['breadunit'], max: 1};
      }
      return retour;
    },
    sumOrders: function(allOrders, menuPart, shift){
      let currSum = 0;
      for(let e in menuPart.elements)
      {
        if(allOrders && allOrders['orders'] && allOrders['orders'][menuPart.elements[e] + '_' + shift])
        currSum += allOrders['orders'][menuPart.elements[e] + '_' + shift]['total'];
      }
      return currSum;
    },
    showToast: function(msg, infoType)
    {
      let toastType = 'is-success', toastDuration = 3000, toastMsg = '<i class="fa fa-check"></i>' + msg;
      if(infoType=='error')
      {
        toastMsg = '<b>' + this.t('main.erroroccurred') + '</b><br><pre class="text-bold small">' + msg + '</pre><span class="small">' + this.t('main.errorcontactadmin');
        toastType = 'is-danger', toastDuration = 5000;
      }

      toast({
        message: '<span><span class="text-xs text-greylight">' + this.formatDateTime(new Date()) + '</span><br>' + toastMsg + '</span>',
        type: toastType,
        dismissible: true,
        pauseOnHover: true,
        duration: toastDuration,
        position: 'bottom-right'
      });
    },
    ucFirst: function(string){
        return ucFirst(string)
    }
  }