<template>
<div class="row mb-3 mt-5 p-3 pt-5">
    <div class="col">
        <h2>{{ t('admon.accounts.title') }}</h2>
    </div>
</div>
<div class="row">
    <div class="col-12 col-md-6 col-lg-4 mb-4"><button class="btn btn-primary form-control" @click="addAccountRow()">{{ t('admon.accounts.add') }}</button></div>
</div>
<div class="row">
    <div class="col-12 col-md-6 col-lg-4 mb-4" v-for="account in newAccounts" :key="account.id">
        <div class="card">
            <div class="card-header text-white bg-primary">{{ t('admon.accounts.new') }}</div>
            <div class="card-body">
                <input class="form-control" :class="fieldClasses(account, 'last_name', 'new')" v-model="account['last_name']" @change="editedAccount(account, 'last_name')" :placeholder="t('admon.accounts.last_name')" required>
                <div class="invalid-feedback">{{ invalidText(account.id, 'last_name') }}</div>
                <input class="form-control" :class="fieldClasses(account, 'first_name', 'new')" v-model="account['first_name']" @change="editedAccount(account, 'first_name')" :placeholder="t('admon.accounts.first_name')" required>
                <div class="invalid-feedback">{{ invalidText(account.id, 'first_name') }}</div>
                <input class="form-control" :class="fieldClasses(account, 'email', 'new')" v-model="account['email']" @change="editedAccount(account, 'email')" :placeholder="t('admon.accounts.email')" required>
                <div class="invalid-feedback">{{ invalidText(account.id, 'email') }}</div>
                <input class="form-control" :class="fieldClasses(account, 'pwd', 'new')" v-model="account['pwd']" @change="editedAccount(account, 'pwd')" :placeholder="t('admon.accounts.password')" type="password">
                <div class="invalid-feedback">{{ invalidText(account.id, 'pwd') }}</div>
                <input class="form-control" :class="fieldClasses(account, 'pwd2', 'new')" v-model="account['pwd2']" @change="editedAccount(account, 'pwd2')" :placeholder="t('admon.accounts.passwordconfirm')" type="password">
                <div class="invalid-feedback">{{ invalidText(account.id, 'pwd2') }}</div>
                <select class="form-select" :class="fieldClasses(account, 'entity', 'new')" v-model="account['entity']" @change="editedAccount(account, 'entity')" :placeholder="t('admon.accounts.entity')" required>
                    <option v-for="entity in allEntities" :key="entity.id" :value="entity.id">{{ entity ? entity.name : '' }}</option>
                </select>
                <div class="invalid-feedback">{{ invalidText(account.id, 'entity') }}</div>
                <select class="form-select" v-model="account['role']" @change="editedAccount(account, 'role')" :placeholder="t('admon.accounts.role')" required>
                    <option v-for="(role, key) in accountRoles" :key="key" :value="key">{{ role }}</option>
                </select>
                <div class="invalid-feedback">{{ invalidText(account.id, 'role') }}</div>
                <div class="row mt-2">
                    <div class="col"><label><input type="checkbox" v-model="account['can_order_collective']" @change="editedAccount(account, 'can_order_collective')"> {{ t('admon.accounts.can_order_collective') }}</label></div>
                    <div class="col"><label><input type="checkbox" v-model="account['can_order_horizons']" @change="editedAccount(account, 'can_order_horizons')"> {{ t('admon.accounts.can_order_horizons') }}</label></div>
                </div>
                <div class="row mt-2">
                    <div class="col"><button class="btn btn-primary me-2 form-control" v-if="editedAccounts[account.id]" @click="createAccount(account)"><font-awesome-icon :icon="['fas', 'save']"  /></button></div>
                    <div class="col-4"><button class="btn btn-danger form-control" @click="deleteAccount(account)"><font-awesome-icon :icon="['fas', 'trash']"  /></button></div>
                </div>
            </div>
            <div class="card-footer text-grey"><small>&nbsp;</small></div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-md-6 col-lg-4 mb-4" aria-hidden="true" v-if="dictLength(allAccounts)==0">
        <div class="card placeholder-glow">
            <div class="card-header text-white border-dark bg-secondary">
                <span class="placeholder col-6"></span>
            </div>
            <div class="card-body">
                <span class="placeholder placeholder-lg col-12 mt-1 mb-3"></span>
                <span class="placeholder placeholder-lg col-12 mt-1 mb-3"></span>
                <span class="placeholder placeholder-lg col-12 mt-1 mb-3"></span>
                <span class="placeholder placeholder-lg col-12 mt-1 mb-3"></span>
                <span class="placeholder placeholder-lg col-12 mt-1 mb-3"></span>
                <div class="row">
                    <div class="col-6"><span class="placeholder placeholder-lg col-12 mt-1 mb-3"></span></div>
                    <div class="col-6"><span class="placeholder placeholder-lg col-12 mt-1 mb-3"></span></div>
                </div>
                <div class="mt-5 mb-4">&nbsp;</div>
            </div>
            <div class="card-footer">
                <span class="placeholder col-9 mt-1 mb-2"></span>
                <span class="placeholder col-7 mt-1 mb-2"></span>
                <span class="placeholder col-8 mt-1 mb-2"></span>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-4" v-for="account in sortedAccounts" :key="account.id">
        <div class="card" :class="editedAccounts[account.id] ? 'border-primary' : allAccounts[account.id]['role'] == 'admin' ? 'border-success' : 'border-dark'">
            <div class="card-header text-white" :class="allAccounts[account.id]['role'] == 'admin' ? 'bg-success' : 'bg-secondary'">{{ t('admon.accounts.' + allAccounts[account.id].role) }}</div>
            <div class="card-body">
                <input class="form-control" :class="fieldClasses(allAccounts[account.id], 'last_name', 'update')" v-model="allAccounts[account.id]['last_name']" @change="editedAccount(allAccounts[account.id], 'last_name')" :placeholder="t('admon.accounts.last_name')">
                <div class="invalid-feedback">{{ invalidText(account.id, 'last_name') }}</div>
                <input class="form-control" :class="fieldClasses(allAccounts[account.id], 'first_name', 'update')" v-model="allAccounts[account.id]['first_name']" @change="editedAccount(allAccounts[account.id], 'first_name')" :placeholder="t('admon.accounts.first_name')">
                <div class="invalid-feedback">{{ invalidText(account.id, 'first_name') }}</div>
                <input class="form-control" :class="fieldClasses(allAccounts[account.id], 'email', 'update')" v-model="allAccounts[account.id]['email']" @change="editedAccount(allAccounts[account.id], 'email')" :placeholder="t('admon.accounts.email')">
                <div class="invalid-feedback">{{ invalidText(account.id, 'email') }}</div>
                <select class="form-select" v-model="allAccounts[account.id]['entity']" @change="editedAccount(allAccounts[account.id], 'entity')">
                    <option v-for="entity in allEntities" :key="entity.id" :value="entity.id" :selected = "account['entity'] == entity.id">{{ entity ? entity.name : '' }}</option>
                </select>
                <div class="invalid-feedback">{{ invalidText(account.id, 'entity') }}</div>
                <select class="form-select" v-model="allAccounts[account.id]['role']" @change="editedAccount(allAccounts[account.id], 'role')">
                    <option v-for="(role, key) in accountRoles" :key="key" :value="key" :selected="account['role'] == key">{{ role }}</option>
                </select>
                <div class="invalid-feedback">{{ invalidText(account.id, 'role') }}</div>
                <div class="row mt-2">
                    <div class="col"><label><input type="checkbox" v-model="allAccounts[account.id]['can_order_collective']" @change="editedAccount(allAccounts[account.id], 'can_order_collective')"> {{ t('admon.accounts.can_order_collective') }}</label></div>
                    <div class="col"><label><input type="checkbox" v-model="allAccounts[account.id]['can_order_horizons']" @change="editedAccount(allAccounts[account.id], 'can_order_horizons')"> {{ t('admon.accounts.can_order_horizons') }}</label></div>
                </div>
                <div class="row mt-2">
                    <div class="col-auto"><button class="btn btn-outline-secondary form-control" @click="changePwdAccount(allAccounts[account.id])"><font-awesome-icon :icon="['fas', 'lock']"  /></button></div>
                    <div class="col" :class="allAccounts[account.id]['showpwd'] ? '' : 'd-none'">
                        <input class="form-control" :class="fieldClasses(allAccounts[account.id], 'pwd', 'update')" v-model="allAccounts[account.id]['pwd']" @change="editedAccount(allAccounts[account.id], 'pwd')" :placeholder="t('admon.accounts.password')" type="password">
                        <div class="invalid-feedback">{{ invalidText(account.id, 'pwd') }}</div>
                        <input class="form-control" :class="fieldClasses(allAccounts[account.id], 'pwd2', 'update')" v-model="allAccounts[account.id]['pwd2']" @change="editedAccount(allAccounts[account.id], 'pwd2')" :placeholder="t('admon.accounts.passwordconfirm')" type="password">
                        <div class="invalid-feedback">{{ invalidText(account.id, 'pwd2') }}</div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col" style="height: 65px;"><button class="btn btn-primary me-2 form-control" v-if="editedAccounts[account.id]" @click="updateAccount(allAccounts[account.id])"><font-awesome-icon :icon="['fas', 'save']"  /></button></div>
                    <div class="col-4" v-if="$store.state.user.id != account.id">
                        <button class="btn btn-secondary form-control" @click="deleteAccount(account)"><font-awesome-icon :icon="['fas', 'trash']"  /></button>
                    </div>
                </div>
            </div>
            <div class="card-footer text-grey">
                <small>
                    {{ t('admon.accounts.last_login') }}: {{ formatDateTime(account.last_login) }}
                    <br>{{ t('main.created') }}: {{ formatDateTime(account.date_c) }}
                    <br>{{ t('main.lastupdate') }}: {{ formatDateTime(account.date_m) }}
                </small>
            </div>
        </div>
    </div>
</div>

</template>


<script>
// @ is an alias to /src¡
import { useI18n } from 'vue-i18n';
import shared from '@/components/shared';
import axios from 'axios';
import sharedMixin from '@/mixins/sharedMixin';

export default {
    name: 'AdmonAccounts',
    mixins: [sharedMixin],
    created(){
    },
    components: {

    },
    computed: {
        accountRoles() {
            return {'client': this.t('admon.accounts.client'), 'admin': this.t('admon.accounts.admin')}; 
        },
        sortedAccounts() {
            return Object.values(this.originalAccounts).sort((a, b) => {
                const lastNameComparison = a.last_name.localeCompare(b.last_name);
                if (lastNameComparison !== 0) {
                return lastNameComparison;
                }
                return a.first_name.localeCompare(b.first_name);
            });
        }
    },
    data() {
        return {
        allAccounts: {},
        allEntities: {},
        editedAccounts: {},
        errorsInAccounts:{},
        originalAccounts: {},
        newAccounts: {},
        }
    },
    methods: {
        addAccountRow(){
            let accountId = 'NEW_' + Date.now();
            this.newAccounts[accountId] = { id: accountId, last_name: '', first_name: '', email: '', entity: '', can_order_collective: true, can_order_horizons: false, role: 'client' };
        }, 
        copyAllAccounts(){
            this.originalAccounts = this.copyVar(this.allAccounts);
        },
        createAccount(account){
            delete this.errorsInAccounts[account.id];
            axios.post('/api/v1/account/', account)
            .then(response => {
                if('id' in response.data){
                    this.allAccounts[response.data.id] = response.data;
                    this.copyAllAccounts();
                    delete this.newAccounts[account.id];
                    delete this.editedAccounts[account.id];
                    this.showToast(this.t('admon.accounts.created') + response.data.fullname);
                }
                else this.handleGeneralError(response);
            })
            .catch(error => {
                this.showErrors(account, 'update', error);
            });
        },
        deleteAccount(account){
            if(confirm(account.fullname ? this.t('admon.accounts.confirm_delete') + ' ' + account.fullname + '?' : this.t('admon.accounts.confirm_delete_new') + '?'))
            {
                console.log(account);
                if(!Number.isInteger(account.id)) delete this.newAccounts[account.id];
                else
                {
                    axios.delete('/api/v1/account/' + account.id + '/')
                    .then(response => {
                        if(response.status == 204){
                            this.showToast(this.t('admon.accounts.deleted') + account.fullname);
                            delete this.allAccounts[account.id];
                            delete this.originalAccounts[account.id];
                            delete this.editedAccounts[account.id];
                        }
                        else this.handleGeneralError(response);
                    })
                    .catch(error => {
                        this.showErrors(account, 'delete', error);
                    });
                }
            }
        },
        editedAccount(account, field){
            if(
                account.id in this.editedAccounts && 
                account.id in this.originalAccounts && 
                this.allAccounts[account.id][field] == this.originalAccounts[account.id][field]
            )
            {
                delete this.editedAccounts[account.id][field];
                if(Object.keys(this.editedAccounts[account.id]).length == 0) delete this.editedAccounts[account.id];
            }
            else
            {
                if(!this.editedAccounts[account.id]) this.editedAccounts[account.id] = {};
                this.editedAccounts[account.id][field] = account[field]; 
            }
        },
        fieldClasses(account, fieldName, fieldType) {
            let ret = '';
            if(['first_name', 'last_name'].indexOf(fieldName)>-1)
            {
                ret += account[fieldName]!='' ? ' input-title' : '';
            }
            if(account.id in this.errorsInAccounts)
            {
                if(this.errorsInAccounts[account.id][fieldName]) ret += ' is-invalid';
            }
            return ret;
        },
        getAllAccounts(){
            axios.get('/api/v1/accounts/')
            .then(response => {
                if(response.status == 200){
                    for(let e in response.data)
                    {
                    this.allAccounts[response.data[e].id] = response.data[e]
                    }
                    this.copyAllAccounts();
                }
                else this.handleGeneralError(response);
            })
            .catch(error => {
                this.handleGeneralError(error);
            });
        },
        invalidText(accountId, invalidText)
        {
            if(accountId in this.errorsInAccounts) return this.errorsInAccounts[accountId][invalidText];
            return '';
        },
        showErrors(account, accountType, error)
        {
            if(error.status==400)
            {
                for(let e in error.response.data)
                {
                    let errorText = error.response.data[e];
                    if(!this.errorsInAccounts[account.id]) this.errorsInAccounts[account.id] = {};
                    if (Array.isArray(errorText)) 
                    {
                        errorText = errorText[0].replace('.','', 'g');
                    }
                    if(e == 'delete') this.handleGeneralError(this.t('admon.accounts.errors.' + errorText));
                    else this.errorsInAccounts[account.id][e] = this.t('admon.accounts.errors.' + errorText); 
                }
            }
            else
            {
                this.handleGeneralError(JSON.stringify(error.response.data));
            }
        },
        changePwdAccount(account){
            if(!account.showpwd) account.showpwd = true;
            else 
            {
                account.showpwd = false;
                delete this.editedAccounts[account.id]['pwd'];
                delete this.editedAccounts[account.id]['pwd2'];
            }
        },
        updateAccount(account){
            delete this.errorsInAccounts[account.id];
            axios.patch('/api/v1/account/' + account.id + '/', this.editedAccounts[account.id])
            .then(response => {
                if(response.status == 200){
                    this.allAccounts[account.id] = response.data;
                    this.copyAllAccounts();
                    delete this.editedAccounts[account.id];
                    this.showToast(response.data.fullname + this.t('admon.accounts.updated'));
                }
                else this.handleGeneralError(response);
            })
            .catch(error => {
                this.showErrors(account, 'update', error);
            });
        },
    },
    setup() {
        const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
        })
        return { t }
    },
    async mounted() {
      await this.getAllAccounts(this.getAllEntities(this.copyAllAccounts));
    },
}
</script>