import shared from '@/components/shared';

export default {
    created() {
        this.ucFirst = shared.ucFirst;
        this.dictLength = shared.dictLength;
        this.copyVar = shared.copyVar;
        this.formatDateRaw = shared.formatDateRaw;
        this.formatDate = shared.formatDate;
        this.formatDateTime = shared.formatDateTime;
        this.getAllEntities = shared.getAllEntities;
        this.handleGeneralError = shared.handleGeneralError;
        this.logout = shared.logout;
        this.menuChars = shared.menuChars;
        this.sumOrders = shared.sumOrders;
        this.showToast = shared.showToast;
    }
}
