<template>
    <nav class="navbar navbar-expand-lg navbar-dark navbar-cc fixed-top">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand">
          <div class="row">
            <div class="col-auto"><img alt="Les Fourneaux de Marthe et Matthieu logo" src="./assets/img/logo_navbar.png" height="35" class="me-2"></div>
            <div class="col"><strong>LES FOURNEAUX <br class="d-block d-xl-none">DE MARTHE &amp; MATTHIEU</strong></div>
          </div>
        </router-link>
        <router-link to="/" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </router-link>
        <template v-if="$store.state.isAuthenticated">
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                 <router-link :to="{name:'home'}" class="nav-link" :class="$route.name=='home' ? 'active': ''">{{ t('menu.home') }}</router-link>
              </li>
              <li class="nav-item" v-if="$store.state.user.can_order_horizons">
                 <router-link :to="{name:'CalendarMain', params: {menutype: 'horizons'}}" class="nav-link" :class="$route.params.menutype=='horizons' ? 'active': ''">{{ t('menu.horizons') }}</router-link>
              </li>
              <li class="nav-item" v-if="$store.state.user.can_order_collective">
                 <router-link :to="{name:'CalendarMain', params: {menutype: 'collective'}}" class="nav-link" :class="$route.params.menutype=='collective' ? 'active': ''">{{ t('menu.collective') }}</router-link>
              </li>
              <li class="nav-item dropdown" v-if="isAdmin">
                <router-link to="#"  class="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false"  :class="$route.meta.isAdmonView ? 'active': ''">{{ t('menu.admon.main') }}</router-link>
                <ul class="dropdown-menu" aria-labelledby="adminDropdown">
                  <li>
                    <router-link :to="{name:'AdmonAccounts'}" class="dropdown-item" :class="$route.name=='AdmonAccounts' ? 'active': ''">{{ t('menu.admon.accounts') }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'AdmonEntities'}" class="dropdown-item"  :class="$route.name=='AdmonEntities' ? 'active': ''">{{ t('menu.admon.entities') }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="userAccount">
              <div class="row"><div class="col">{{ $store.state.user.first_name }}</div></div>
              <div class="row"><div class="col small">({{ $store.state.user.entity_name }})</div></div>
            </div>
            <button @click="logout()" class="btn btn-outline-secondary ms-4">{{ t('login.logout') }}</button>
          </div>

      </template>
      </div>
    </nav>


    <template v-if="app_env!='PRD'">
      <div class="alert alert-danger cursor-hand mt-0 ms-0 me-0 mb-5" role="alert" onclick="window.location.href = 'https://fmm-collective.fr';">[{{ app_env }}] {{ t('main.uat') }}. {{ t('main.clickhere') }}</div>
    </template>
    <section class="container">
      <router-view :key="$route.fullPath"/>
    </section>

 

    <nav class="navbar fixed-bottom bg-light footer-cc">
      <div class="container-fluid small" v-html="copyrightText"></div>
    </nav>
</template>

<script>
import axios from "axios";
import { useI18n } from 'vue-i18n';
import shared from './components/shared'


export default {
  name: "App",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global'
    })
    return { t }
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  created() {
    this.logout = shared.logout
  },
  data(){
    return {
      app_env: ''
    }
  },
  computed: {
    copyrightText(){
      const currentYear = new Date().getFullYear();
      return " &copy; " + currentYear + " " + this.t('footer.allrights');
    },
    isAdmin(){
      return this.$store.state.user.role=='admin';
    },
  },
  mounted() {
    this.app_env = process.env.VUE_APP_ENV
  },
  methods: {
    
  },
};
</script>

<style lang="scss" src="./assets/css/fmm.scss">
</style>