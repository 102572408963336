export default {
  "calendar": {
    "days": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])}
    },
    "months": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
    },
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])}
  },
  "daymenu": {
    "starters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starters"])},
    "mains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Courses"])},
    "sides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sides"])},
    "dairy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dairy"])},
    "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])},
    "bread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bread"])},
    "breadunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bread by unit"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LUNCH"])},
    "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DINNER"])},
    "addok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu added successfully"])},
    "allmenus_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LUNCH (All)"])},
    "allmenus_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DINNER (All)"])},
    "allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of allergens"])},
    "allergies_filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALLERGENS_LIST"])},
    "backtocalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to calendar"])},
    "calendar_byday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Menus"])},
    "calendar_bymonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Menus"])},
    "error_checkboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected the maximum number of options for this section. Please uncheck an option before selecting another."])},
    "error_menustotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of ordered items exceeds the entered number. Please correct the menu items first."])},
    "error_qty_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning, there are differences between the desired number of menus and the ordered quantities (this is non-blocking)"])},
    "error_qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested sum cannot exceed the number of menus."])},
    "deliveryforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery forms"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "fileadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File added"])},
    "filedeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File deleted"])},
    "fileupdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File updated"])},
    "limit_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration deadline: "])},
    "limit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription deadline: "])},
    "managingforentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders for: "])},
    "meatorigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meat origin"])},
    "meatorigin_filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEAT_ORIGIN"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
    "menustotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of menus"])},
    "nomenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No menu available for this date"])},
    "notfinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not published"])},
    "notvalidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This daily menu is awaiting validation"])},
    "orderaddok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order added"])},
    "orderedmenus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordered menus"])},
    "orderok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order recorded"])},
    "saveok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu saved"])},
    "selectmenustotal_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a number of lunch menus"])},
    "selectmenustotal_s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a number of dinner menus"])},
    "showorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View orders"])},
    "subscribe_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions by entity"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This daily menu has been validated"])},
    "weekmenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly menu"])},
    "weekmenu_lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly menu (Lunch)"])},
    "weekmenu_dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly menu (Dinner)"])}
  },
  "footer": {
    "allrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])}
  },
  "home": {
    "accesscalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access the calendar"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on the ordering site "])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of Fourneaux de Marthe & Matthieu"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])}
  },
  "login": {
    "cantlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your credentials."])},
    "checkfield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the fields."])},
    "fieldnotblank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be filled"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To start, please log in:"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access your space"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User account"])}
  },
  "main": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "created_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "clickhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "erroroccurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred:"])},
    "errorcontactadmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact your administrator if the problem persists."])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "generating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating..."])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["global"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information"])},
    "lastupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "showdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])},
    "uat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THIS IS A TEST SITE - For production use"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
    "updated_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
    "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
  },
  "menu": {
    "collective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collective Catering"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "horizons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homemade Horizons"])},
    "admon": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
      "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User accounts"])},
      "entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sites"])}
    }
  },
  "admon": {
    "accounts": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an account"])},
      "addok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
      "can_order_collective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collective"])},
      "can_order_horizons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons"])},
      "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the account of:"])},
      "confirm_delete_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this account"])},
      "date_joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
      "deleteok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User deleted"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
      "editok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User edited"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity"])},
      "errors": {
        "It is mandatory to provide a last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name is mandatory"])},
        "It is mandatory to provide a first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name is mandatory"])},
        "It is mandatory to provide a username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username is mandatory"])},
        "It is mandatory to provide a password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is mandatory"])},
        "It is mandatory to provide a role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role is mandatory"])},
        "It is mandatory to provide an entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity is mandatory"])},
        "It is mandatory to provide an e-mail address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is mandatory"])},
        "An account with this email already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An account with this email already exists"])},
        "This field may not be blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field may not be blank"])},
        "This field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
        "The passwords do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passwords do not match"])},
        "It is mandatory to confirm the password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation is mandatory"])},
        "The password must have at least 8 characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must have at least 8 characters"])},
        "The password must have at least one number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must have at least one number"])},
        "The password must have at least one uppercase letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must have at least one uppercase letter"])},
        "The password must have at least one lowercase letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must have at least one lowercase letter"])}
      },
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last login"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "passwordconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
      "passwordnotmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
      "passwordrequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
      "passwordtooshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 6 characters"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "roleadmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
      "roleuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User account administration"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" updated."])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])}
    },
    "entities": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a site"])},
      "addok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete:"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site created successfully"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete site"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site deleted successfully"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site administration"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit site"])},
      "editok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site edited"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site name"])},
      "open_date_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date editing open"])},
      "open_date_limits_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date editing closed"])},
      "open_date_limits_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No date limit"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" updated"])}
    }
  }
}